
addEventListener('DOMContentLoaded', () => {

	// Load Microsoft script
	const script = document.createElement('script')
	script.src = 'https://alcdn.msauth.net/browser/2.18.0/js/msal-browser.min.js'
	script.type = 'text/javascript'
	script.async = true
	document.head.appendChild(script)

	// Login
	const entrA = document.querySelector('a.entra_button')
	if (entrA) {

		script.onload = function () {
			entrA.classList.remove('hidden')

			// Set some variables
			const msalConfig = {
				auth: {
					clientId: entrA.getAttribute('data-clientid'),
					authority: 'https://login.microsoftonline.com/common',
					redirectUri: entrA.getAttribute('data-redirect-url')
				}
			}
			const myMSALObj = new msal.PublicClientApplication(msalConfig)
			const loginRequest = { scopes: ['User.Read', 'offline_access'] }

			entrA.addEventListener('click', () => {

				myMSALObj.loginPopup(loginRequest)
					.then(loginResponse => {

						const idtokenInput = document.querySelector('input#entra_idtoken')
						if (idtokenInput) {
							idtokenInput.value = loginResponse.idToken
						}

						const frmLogin = document.querySelector('form#frmLogin')
						if (frmLogin) {
							frmLogin.submit()
						}
					})
					.catch(error => {
						console.error('Error logging in:', error)
					})
			})

		}

	}
})

