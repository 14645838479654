import './dropdown.jsx'
import './filebrowser'
import './fileselect'
import './keywordsfield.jsx'
import './selection.jsx'
import './thumbnailselect.jsx'
import './entra.jsx'

addEventListener('DOMContentLoaded', (event) => {
	let textfields = document.querySelectorAll('ui-textfield')

	for (const textfield of textfields) {
		const input = textfield.querySelector('input')
		if (input) {
			input.addEventListener('focus', () => textfield.classList.add('focus'))
			input.addEventListener('blur', () => textfield.classList.remove('focus'))
		}
	}
})